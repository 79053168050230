/**
 * Slideshow vezérlés
 *
 * @author Istvan Katona<web@ampmmarketing.eu>
 * @copyright 2015 ampm marketing kft. All rights reserved.
 */
var Slideshow = (function (s, $) {

    var sh = s || {},
        tfTo = null,
        timeFormat = 0, // 12 vagy 24
        inputFocus = false,
        url = '',
        token = '',
        timerObj = null,
        isRunning = false,
        imageProcessPending = false,
        /**
         * Megjelenített kép indexe a slideshowimage osztályú képek között
         *
         * @type {number}
         */
        index = 0,
        second = {h: 0, m: 0, s: 0},
        currentSecondNumber = 0,
        td = $('.time-display'),
        bh = td.find('.block.hour'),
        bm = td.find('.block.minute'),
        bs = td.find('.block.second'),
        isAm = !(new Date().getHours() >= 12),
        /**
         * Aktuális slideshow kép
         *
         * @type {jQuery object}
         */
        activeElement = null,
        /**
         * Már megjelent DOM fáról eltávolítható képek tárolója
         *
         * @type {Array}
         */
        trash = [],
        /**
         * Spinner
         *
         * @param {jQuery object}
         */
        spinner = $('#slideshow-spinner'),
        /**
         * A slideshow megállításának módja
         * - standard a felhasználó a pause gombra kattintott
         * - picsharer a képmegosztás ikonok megjelentek
         *
         * @type {string}
         */
        pauseMode = 'standard',
        /**
         * inaktivitás számláló
         *
         * @type {number}
         */
        inactiveTicker = 0,
        inactiveIntervalObj = null,
        /**
         * A kliens tablet-e vagy nem tablet
         *
         * @type {boolean}
         */
        tabletta = false,
        /**
         * már csak ennyi megjelenítésre váró kép van akkor be kell tölteni a további képeket
         *
         * @type {number}
         */
        offset = 10,

        controlPanelTo = null,

        picSharerTo = null,

        picSharerFullOpen = false,
        /**
         * Lapozás blokkolása amennyiben nincs elegendő kép betöltve a lapozás folyamatos használatához
         *
         * @type {boolean}
         */
        blockPager = false,

        /**
         * Slideshow képek váltása
         */
        switching = function () {
            var sliderImages = $('.sliderimage');
            // a másodperc képe az aktuális kép
            sliderImages.each(function (i, item) {
                //console.log($(item).data('imgData').second +' == '+ currentSecondNumber);
                if ($(item).data('imgData').second == currentSecondNumber) {
                    activeElement = $(item);
                    return true;
                }
            });

            activeElement.show();
            //console.log(activeElement.data());
            index = $('.sliderimage:visible').length;
            //console.log(index);

            // vízjel kell a mintaképeken
            watermark();

            // képet törölni lehet miután megjelent
            trash.push(activeElement.prev('.sliderimage'));

            //console.info('Index is: ' + index);
            //console.info('Sliderimages length is: ' + sliderImages.length);

            // további képek betöltése
            if (index + offset >= sliderImages.length) {
                //console.warn('load initiator switching');
                loadMoreImages(true, false, false, null, function () {
                    // megejelenített képek eltávolítása a DOM fáról
                    clearTrash();

                    index = 1;
                });
            }
        },

        clearTrash = function () {
            for (var i = 0; i < trash.length; i++) {
                trash[i].remove();
            }
        },

        /**
         * A mintaképekre vízjelet kell helyezni
         * Néhány mintaképhez szerzőt is meg kell jelölni.
         */
        watermark = function () {
            var sampleOverlay = $('#sampleoverlay'),
                copyrightContent = $('.img-copyright-content');

            $('.img-copyright-content:visible').hide();

            // csak nem eladott vagy eladott de még nem elfogadott másodpercképeken lehetséges vízjel
            if (!activeElement.data('imgData').sold || (activeElement.data('imgData').sold && !activeElement.data('imgData').verified)) {
                // csak ha rejtve van
                if (sampleOverlay.is(':hidden')) {
                    sampleOverlay.on('click', function () {
                        // a kép onclick esemény a vízjel overlayre kattintással is le kell futnia
                        activeElement.trigger('click');
                    }).show();
                }
                if (activeElement.data('imgData').copyright != '') {
                    copyrightContent.text(activeElement.data('imgData').copyright).show();
                }
            }
            if (activeElement.data('imgData').sold != false) {
                console.log('sold');
                sampleOverlay.css('display', 'none');
                copyrightContent.css('display', 'none');
            } else {
                sampleOverlay.css('display', 'block');
                copyrightContent.css('display', 'block');
            }
        },

        /**
         * További másodpercképek betöltése
         *
         * @param {boolean} [orient=true] következő másodpercek (true, default) vagy előző másodpercek (false) betöltése
         * @param {boolean} [continued=false]
         * @param {boolean} [setFirst=false]
         * @param {number} [setSecond=null] másodperc direkt beállítása
         * @param {function} [callback=undefined] callback függvény betöltött képek számát adja á paraméterben
         */
        loadMoreImages = function (orient, continued, setFirst, setSecond, callback) {
            if (imageProcessPending) {
                // aszinkron hívás ismétlésének megelőzése
                return false;
            }

            imageProcessPending = true;

            var _second = typeof setSecond == 'undefined' ? null : setSecond,
                _orient = typeof orient == 'undefined' ? true : orient,
                _setFirst = typeof setFirst == 'undefined' ? false : setFirst,
                sliderImgLength = $('.sliderimage').length;

            if (activeElement) {
                if (_orient) {
                    // másodperceket előre kell betölteni
                    activeElement.prevAll('.sliderimage').slice(offset).remove();
                    // index pozíciót újra be kell állítani mert a negatív irányba lopzásnál eltolódik
                    index = $('.sliderimage:visible').length;
                } else {
                    // másodperceket hátra kell betölteni
                    activeElement.nextAll('.sliderimage').slice(offset).remove();
                }
            }

            $.ajax({
                type: 'POST',
                url: url + '/getImages',
                data: {
                    // képváltás iránya
                    orientation: typeof orient == 'undefined' ? true : orient,
                    // megállított slideshow folytatása vagy sem
                    continued: typeof continued == 'undefined' ? false : continued,
                    // kliens idővel kell dolgozni a szerver oldalon is!
                    // első oldalbetöltéskor van jelentősége
                    clientTime: _second,
                    f: sliderImgLength > 0 ? $('.sliderimage:first').data('imgData').second : 0,
                    l: sliderImgLength > 0 ? $('.sliderimage:last').data('imgData').second : 0
                },
                headers: token
            }).done(function (result) {
                if (result.success) {
                    spinner.hide();

                    sh.setImages(result.images, _setFirst, orient, _second);

                    imageProcessPending = false;

                    if ($.isFunction(callback)) {
                        callback(result.images.length);
                    }
                }

                // TODO: success false?
            });
        },

        /**
         * Átirányítás a másodperc vásárláshoz
         */
        redirectToStore = function (addSecond) {
            //return false;
            setTimeout(function () {
                if (addSecond) {
                    sh.to('pic-a-second?add=' + addSecond);
                } else {
                    sh.to('pic-a-second');
                }
            }, 2000);
        },

        /**
         * Idő váltása
         *
         * @param [setTime=null] DateTime objektum
         * @param [next=true] időben előrehaladás vagy sem (false)
         */
        timeDisplay = function (setTime, next) {
            var d = new Date(),
                st = setTime || null,
                n = typeof next === 'undefined' ? true : next;

            if (st != null) {
                d.setHours(isAm ? st.getHours() : st.getHours() + 12);
                d.setMinutes(st.getMinutes());
                d.setSeconds(st.getSeconds());
            }

            var dh = d.getHours(),
                dm = d.getMinutes(),
                ds = d.getSeconds();

            currentSecondNumber = ((dh * 3600) + (dm * 60) + (ds));
            console.info((((dh * 3600) + (dm * 60) + (ds)) % 7200) + '/' + currentSecondNumber);
            console.log(dh + ':' + dm + ':' + ds);
            if (timeFormat == 12) {

                if ((dh == 12 || dh == 0) && dm == 0 && ds == 0 && n === true) {
                    isAm = !isAm;
                }
                //console.log(isAm);

                td.find('.am,.pm').removeClass('active');
                td.find(isAm ? '.am' : '.pm').addClass('active');

                dh = dh % 12;
                dh = dh ? dh : 12;
            }

            second = {h: dh, m: dm, s: ds};

            var time = sh.parseTime(second);

            bh.text(time.hour);
            bm.text(time.minute);
            bs.text(time.second);
        };

    sh.to = function (u) {
        window.location.href = url + '/' + u;
    };

    sh.getIndex = function () {
        return index;
    };

    /**
     * Slideshow egy képére történt kattintás
     */
    sh.imgClick = function () {
        var imgData = $(this).data('imgData');
        console.log(imgData);
        // slideshow megáll
        sh.pause('picsharer');
        // vízjel elrejtése
        //$('#sampleoverlay, .img-copyright-content').hide();
        $('.img-copyright-content').hide();

        if (imgData.sold) {
            if (!imgData.verified) {
                // elada de még nincs elfogadva
                $('#overlay-sold').show();
                $('a.click-here').show();

                redirectToStore();
                return;
            } else if (!imgData.linkPresent) {
                $('#overlay-no-link').show();
                $.ajax({
                    type: 'POST',
                    url: url + '/statPlusOne',
                    data: {second : imgData.second},
                    headers: token
                }).done(function (result) {

                });
                return;
            }
        } else {
            if (imgData.available) {
                // eladható másodperc
                $('#overlay-free').show();
                $('a.click-here').attr('href', url + '/pic-a-second?add=' + imgData.second);
                $('a.click-here').show();

                redirectToStore(imgData.second);
            } else {
                // nem eladható másodperc
                //$('.sliderimage').hide();
                $('#overlay-soon').show();
                $('a.click-here').show();

                redirectToStore();
            }

            return;
        }

        if (imgData.linkPresent) {
            // link megnyitása, ugyanitt klikkszám növelése
            window.open(url + '/out/' + imgData.second);
        }
    };

    /**
     * Képek feldolgozása
     *
     * @param {{src: string, sold: boolean, verified: boolean, available: boolean, linkPresent: boolean, second: number, copyright: string}} imgObj
     * @param {boolean} [setFirst=false]
     * @param {boolean} [orient=true]
     * @param {number} [setSecond=null]
     * @return {Slideshow} Slideshow objektum láncolhatóság miatt
     */
    sh.setImages = function (imgObj, setFirst, orient, setSecond, callback) {
        var cnt = $('#psimg'),
        // az új elemek a sor végére (true) vagy elejére (false) kerüljenek
            orientation = typeof orient == 'undefined' ? true : orient,
            _second = typeof setSecond == 'undefined' ? null : setSecond,
            done = 0,
            cb = $.isFunction(callback) ? callback : null;

        for (var elem in imgObj) {
            var p = $(new Image());
            (function (element, picture) {
                var img = imgObj[element];
                /**
                 * slider elemre kattintás
                 */
                picture
                    .attr('src', img.src)
                    .data('imgData', img)
                    .addClass('sliderimage img-responsive');

                if (orientation) {
                    // új képek a lista végére kell illeszteni
                    picture.appendTo(cnt);
                } else {
                    // új képeket a legelső kép elé kell tenni
                    picture.show().insertBefore($('.sliderimage:first'));
                }

                done++;

                /**
                 * Másodpercre ugrás (goto vagy első betöltés vagy ?time= url változó jelen van)
                 */
                //console.log(_second +'=='+ parseInt(img.second));
                //console.log('setSecond = ' + _second);
                if (_second == parseInt(img.second)) {
                    picture.show();

                    $('a.click-here:hidden').hide();

                    // Miután kiválasztottuk legyen megint nullázva az input
                    $('input[name="manual-time"]').val('');

                    // ez a másodperc aktív
                    activeElement = picture;
                    // az összes előző kép megjelenítése
                    setTimeout(function () {
                        // valami oknál fogva ezen halmaz egy képe előbb látszik mint néhány sorral feljebb megjelenített kép
                        picture.prevAll('.sliderimage:hidden').show();

                        //console.log(imgObj.length + ' == ' + done);
                        if (imgObj.length == done && cb !== null) {
                            //console.log('setImages callback');
                            cb();
                        }
                    }, 600);
                }
            }(elem, p));
        }

        cnt.find('.sliderimage').off().on('click', sh.imgClick);

        return sh;
    };

    /**
     * Oldalbetöltéskor slideshow indítása
     */
    sh.start = function (u, t) {
        var d = new Date(),
            s = (d.getHours() * 3600) + (d.getMinutes() * 60) + d.getSeconds();

        url = u;
        token = t;

        sh.showSlideshowControlPanel();
        sh.picShareHandle();

        // időpontra ugrás mező mask
        $('input[name=manual-time]').on('focus', function () {
            // belekattintáskor a control sáv ne tűnjön el
            inputFocus = true;
        }).on('blur', function () {
            // csak ha lost focus
            inputFocus = false;
        }).on('keyup', function (e) {
            // enter leütésére is ugorjon a másodpercre
            if (e.keyCode == 13) {
                if (Inputmask.isValid($(this).val(), {alias: 'hh:mm:ss'})) {
                    $(this).removeClass('error');
                    // csak a szabályos értékkel működik az enter
                    Slideshow.goto(e);
                } else {
                    $(this).addClass('error');
                }
            }
        }).inputmask({alias: 'hh:mm:ss', hourFormat: timeFormat.toString(), placeholder: 'HH:MM:SS'});

        // am/pm beállítása amennyiben szükséges
        /*if (timeFormat == 12) {
            isAm = d.getHours() < 13;
        }*/

        // tab inaktivitás jelző
        // a felhasználó átkattintott egy másik tabra
        $(window).on('blur focus', function (e) {
            var prevType = $(this).data('prevType');

            if (prevType != e.type) {
                switch (e.type) {
                    case 'blur':
                        sh.startInactiveTicker();
                        break;
                    case 'focus':
                        inactiveTicker = 0;
                        clearInterval(inactiveIntervalObj);
                        break;
                }
            }

            $(this).data('prevType', e.type);
        });

        if (tabletta) {
            $('.control').addClass('tabletta');
        }

        //console.warn('load initiator start');

        loadMoreImages(true, false, true, s, function () {
            spinner.hide();

            trash.push($('.sliderimage:first'));

            isRunning = true;
            sh.startTimer();
        });
    };

    sh.startInactiveTicker = function () {
        // inaktivitást számláló másodpercenként fut le
        inactiveIntervalObj = setInterval(function() {
            inactiveTicker++;
            //console.log('ticker = ' + inactiveTicker);

            if (inactiveTicker == (10 * 60)) {
                // 10 perc inaktivitás után a slideshow leáll
                sh.pause('standard');
                clearInterval(inactiveIntervalObj);
            }
        }, 1000);
    };

    /**
     * Következő kép megjelenítése.
     * Slideshow megáll.
     *
     * @param e event
     */
    sh.next = function (e) {
        e.preventDefault();

        if (blockPager) {
            return false;
        }

        // overlay ikonok elrejtése
        $('.overlayimage').hide();

        sh.pause('standard');

        var d = new Date(),
            sliderImages = $('.sliderimage');
        d.setHours(isAm ? second.h : second.h + 12, second.m, second.s);
        d.setSeconds(d.getSeconds() + 1);

        // következő másodperc
        timeDisplay(d);

        activeElement = activeElement.next().show();

        index++;

        // vízjel kell a mintaképeken
        watermark();

        console.info('Index is: ' + index);
        //console.info('Sliderimages length is: ' + sliderImages.length);

        if (index + offset >= sliderImages.length) {
            //console.warn('load initiator next');
            loadMoreImages(true, false, false, null, function () {
                blockPager = false;
            });
        }

        if (index + (offset / 2) >= sliderImages.length) {
            spinner.show();
            blockPager = true;
        }
    };

    /**
     * Előző kép megjelenítése.
     * Slideshow megáll.
     *
     * @param e event
     */
    sh.prev = function (e) {
        e.preventDefault();

        if (blockPager) {
            return false;
        }

        if (index - offset < (offset / 2)) {
            blockPager = true;
            spinner.show();
            loadMoreImages(false, false, false, null, function (loadedCount) {
                index += loadedCount;
                blockPager = false;
                sh.prev(e);
            });

            return false;
        }

        // overlay ikonok elrejtése
        $('.overlayimage').hide();

        sh.pause('standard');

        if (second.h == 12 && second.m == 0 && second.s == 0) {
            isAm = !isAm;
        }

        // -1 másodperc
        var d = new Date();
        d.setHours(isAm ? second.h : second.h + 12, second.m, second.s);
        d.setSeconds(d.getSeconds() - 1);

        // előző másodperc
        timeDisplay(d, false);

        activeElement.hide();

        // az előző elem lesz a következő megjelenő kép
        activeElement = activeElement.prev('.sliderimage');
        index--;

        console.info('Index is: ' + index);
        //console.info('Sliderimages length is: ' + $('.sliderimage').length);

        // vízjel kell a mintaképeken
        watermark();

        if (index - offset <= 0) {
            //console.warn('load initiator prev');
            // ha kevesebb mint 5 kép van visszafelé akkor be kell tölteni az előző képeket
            loadMoreImages(false, false, false, null, function (loadedCount) {
                blockPager = false;
                // az index száma növekszik a betöltött képek számával
                index += loadedCount;
            });
        }

        if (index < (offset / 2)) {
            spinner.show();
            blockPager = true;
        }
    };

    /**
     * Animáció megállítása
     *
     * @param {string} pMode lásd pauseMode
     */
    sh.pause = function (pMode) {
        if (isRunning) {
            pauseMode = pMode;
            isRunning = false;
            clearInterval(timerObj);

            var control = $('a.nav-control');
            control.find('.fa-pause').hide();
            control.find('.fa-repeat').show();
        }
    };

    /**
     * Megállított slideshow és óra folytatása
     * A folytatás itt azt jelenti, hogy a másodperc az aktuális másodperc lesz
     */
    sh.continue = function () {
        if (!isRunning && spinner.is(':hidden')) {
            // overlay ikonok elrejtése
            $('.overlayimage').hide();
            // ha kitöltötte a go to second mezőt akkor azt is törölni kell itt
            $('input[name=manual-time]').val('');

            isRunning = true;

            // kuka itt üres
            trash = [];

            // preloader
            spinner.show();

            var d = new Date(),
            // aktuális idő a szervernek
                s = (d.getHours() * 3600) + (d.getMinutes() * 60) + d.getSeconds();
            // összes eddigi képre továbbiakban nincs szükség, mert a másodperc amely itt indul már időben eltolódott
            $('.sliderimage').remove();

            //console.warn('load initiator continue');
            // a szükséges aktuális képek betöltése
            loadMoreImages(true, true, true, s, function () {
                isAm = !(new Date().getHours() >= 12);
                // óra és slideshow indul
                sh.startTimer();

                // előről kezdődik a slideshow
                index = 0;

                // preloader
                spinner.hide();
            });

            var control = $('a.nav-control');
            control.find('.fa-pause').show();
            control.find('.fa-repeat').hide();
        }
    };

    /**
     * Másodperc képhez ugrás
     *
     * @param e event
     */
    sh.goto = function (e) {
        var elem = $('input[name=manual-time]');
        if (!Inputmask.isValid(elem.val(), {alias: 'hh:mm:ss'})) {
            elem.addClass('error');
            return false;
        }

        if (elem.inputmask('isComplete')) {
            elem.removeClass('error');
        }

        e.preventDefault();
        sh.pause('standard');
        // overlay ikonok elrejtése
        $('.overlayimage').hide();

        // preloader
        spinner.show();
        // összes eddigi képre továbbiakban nincs szükség, mert a másodperc amely itt indul már időben eltolódott
        $('.sliderimage').remove();

        // a deklaráció a main.js-ben van
        var t = elem.val().split(':'),
            ampm = $('#ampmchooser').find(':selected').text();

        $.ajax({
            type: 'POST',
            url: url + '/getImages',
            data: {
                time: t,
                format: timeFormat,
                ampm: ampm
            },
            headers: token
        }).done(function (result) {
            if (result.success) {
                isAm = ampm == 'am';
                var d = new Date(),
                    setSec = 0;
                // 24 órás formátum kell
                d.setHours(t[0], t[1], t[2]);
                timeDisplay(d);

                if (isAm || timeFormat == 24) {
                    var h = parseInt(t[0]);
                    if (isAm && timeFormat == 12) {
                        h = h % 12;
                    }
                    setSec = (h * 60 * 60) + (parseInt(t[1]) * 60) + parseInt(t[2]);
                } else {
                    setSec = ((parseInt(t[0]) == 12 ? 12 : parseInt(t[0]) + 12) * 60 * 60) + (parseInt(t[1]) * 60) + parseInt(t[2]);
                }

                sh.setImages(result.images, false, true, setSec, function () {
                    // preloader
                    spinner.hide();
                });

                // vízjel kell a mintaképeken
                watermark();

                index = Math.ceil(result.images.length / 2);

                $('#manual-time').blur();
            }
        });

        return false;
    };

    /**
     * Slideshow megállítása/folytatása kapcsoló
     *
     * @param e event
     */
    sh.togglePauseContinue = function (e) {
        e.preventDefault();

        if (spinner.is(':hidden')) {
            isRunning ? sh.pause('standard') : sh.continue();
        }
    };

    sh.getPauseMode = function () {
        return pauseMode;
    };

    /**
     * Idő kijelzése a frame aljára.
     *
     * @param {{h: number, m: number, s: number}} sec
     * @return {{hour: number, minute: number, second: number}}
     */
    sh.parseTime = function (sec) {
        //console.log(sec);
        var h = sec.h.toString().length == 1 ? ('0' + sec.h) : sec.h,
            m = sec.m.toString().length == 1 ? ('0' + sec.m) : sec.m,
            s = sec.s.toString().length == 1 ? ('0' + sec.s) : sec.s;

        return {hour: h, minute: m, second: s};
    };

    /**
     * Slideshow és óra animáció indítása
     */
    sh.startTimer = function () {
        // az aktuális másodperc feldolgozása
        timeDisplay();
        switching();
        // többi másodperc feldolgozása
        timerObj = setInterval(function () {
            timeDisplay();
            switching();
        }, 1000);
    };

    /**
     * Jelenlegi másodperc lekérdezése
     *
     * @return {{h: number, m: number, s:number}} idő objektum
     */
    sh.getCurrentSecond = function () {
        return second;
    };

    /**
     * 12 órás időforma esetén oldalbetöltéskor megadja
     *
     * @param am
     * @return {*|{}}
     */
    sh.setAm = function (am) {
        isAm = am;

        return sh;
    };

    /**
     * Alapértelmezett időformátum beállítása
     *
     * @param {number} tf 12 vagy 24
     */
    sh.setTimeFormat = function (tf) {
        timeFormat = tf;
    };

    /**
     * Jelenlegi másodperc beállítása, amikor az url tartalmazza a time változót
     *
     * @param {{h: number, m: number, s:number}} pSecond idő objektum
     * @param {string} u url
     * @param {string} t token
     */
    sh.setCurrentSecond = function (pSecond, u, t) {
        second = pSecond;
        url = u;
        token = t;

        var control = $('a.nav-control');
        control.find('.fa-pause').hide();
        control.find('.fa-repeat').show();

        //console.warn('load initiator setCurrentSecond');

        loadMoreImages(true, false, false, (parseInt(second.h) * 60 * 60) + (parseInt(second.m) * 60) + parseInt(second.s), function (loadedCount) {
            // preloader
            spinner.hide();

            index = Math.ceil(loadedCount / 2);
        });

        return sh;
    };

    sh.isTablet = function (istablet) {
        if (typeof istablet == 'undefined') {
            return tabletta;
        } else {
            tabletta = istablet;
        }
    };

    /**
     * 12/24 órás formátum kapcsoló
     */
    sh.changeTimeFormat = function (tf, e) {
        e.preventDefault();
        clearTimeout(tfTo);

        if (tf == timeFormat) {
            return false;
        }

        var td = $('.time-display');
        if (tf == 24) {
            // váltás 24 órás forátumra
            td.find('.helper').addClass('hidden');
            $('#ampmwrapper').addClass('hidden');
            timeFormat = 24;
        } else {
            // váltás 12 órás forátumra
            var d = new Date();
            td.find('.am,.pm').removeClass('active');
            td.find(d.getHours() < 12 ? '.am' : '.pm').addClass('active');

            td.find('.helper').removeClass('hidden');
            $('#ampmwrapper').removeClass('hidden');
            timeFormat = 12;
        }

        var input = $('input[name=manual-time]');
        input.val('');
        input.inputmask('remove');
        input.inputmask({alias: 'hh:mm:ss', hourFormat: timeFormat.toString(), placeholder: 'HH:MM:SS'});

        $('.toggler').find('.h12, .h24').removeClass('active');
        $(e.target).addClass('active');

        tfTo = setTimeout(function () {
            $.ajax({
                type: 'POST',
                url: url + '/setTimeFormat',
                headers: token,
                data: {f: timeFormat}
            });
        }, 1000);
    };

    sh.showSlideshowControlPanel = function () {
        if (!tabletta) {
            $('.slideshow-container').on('mouseenter', function () {
                $('.control').slideDown(400);
                clearTimeout(controlPanelTo);
            }).on('mouseleave', function (e) {
                e.stopPropagation();

                controlPanelTo = setTimeout(sh.hideSlideshowControlPanel, 500);
            });
        }
    };
    /**
     * Képváltó konzol elrejtése
     */
    sh.hideSlideshowControlPanel = function () {
        if (!inputFocus) {
            var sharerBox = $('.pic-sharer');
            // ?time= query paraméter jelenlétekor a control megjelenése kényszerítve van
            // ezt a kényszerítést a control elrejtésekor le lehet venni
            $('.control').removeClass('forceshareopen').slideUp(200);
            sharerBox.find('.mo').removeClass('hidden');
            sharerBox.find('.hideable').addClass('hidden');
        }
    };
    /**
     * Képmegosztó ikonok megjelenítése
     */
    sh.picShareHandle = function () {
        $('.sharer').on('mouseenter', function () {
            sh.pause('standard');
            clearTimeout(picSharerTo);

            if (!picSharerFullOpen) {
                $('.pic-sharer').find('li:not(.hideable)').show(300);
            }
        }).on('mouseleave', function () {
            picSharerTo = setTimeout(sh.picShareFloatClose, 500);
        });

        $('.mobile-sharer').on('click', function () {
            var li = $(this).find('li');
            if (li.is(':visible')) {
                $(this).removeClass('active');
                li.hide();
            } else {
                sh.pause('standard');
                $(this).addClass('active');
                li.show();
            }
        });
    };

    /**
     * Képmegosztó ikonok elrejtése
     */
    sh.picShareFloatClose = function () {
        var ps = $('.pic-sharer');

        ps.find('li').hide(300);
        // további ikonok elrejtése
        ps.find('li.hideable').addClass('hidden');
        picSharerFullOpen = false;

        if (sh.getPauseMode() == 'picsharer') {
            // animáció folytatása, amennyiben nem direkt lett megállítva
            sh.continue();
        }
    };

    sh.openMorePictureSharing = function (e) {
        picSharerFullOpen = true;
        e.preventDefault();

        $('li.mo').hide();
        $('.pic-sharer').find('li.hideable').removeClass('hidden').show(200);
    };

    sh.getTimeFormat = function () {
        return parseInt(timeFormat);
    };

    return sh;

}(Slideshow || {}, jQuery));