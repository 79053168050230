var Picasecond = (function (p, $) {

    ///
    /// PRIVATE
    ///

    var site = p || {},
        psUrl = '/',
        token = {};

    ///
    /// PUBLIC
    ///

    site.init = function () {
        $(document).ajaxComplete(function (event, request, settings) {
            // ha a session lejár reload mezőt tartalmazni fog a response, az oldalt frissíteni kell
            if (typeof request.responseJSON.reload !== 'undefined') {
                window.location.reload();
            }
        });

        $('.mailto').each(function () {
            var email = $(this).data('email');
            var rev = email.replace(/[a-zA-Z]/g, function (c) {
                return String.fromCharCode((c <= 'Z' ? 90 : 122) >= (c = c.charCodeAt(0) + 13) ? c : c - 26);
            });
            $(this).attr('href', 'mailto:' + rev);
        });

        return site;
    };

    site.reposition = function () {
        var frame = $('.frame'),
            win = $(window),
            offset = Math.floor((win.height() - frame.height()) / 2) - 5;

        //console.debug(frame.height());

        if (offset > 0) {
            frame.css({marginTop: offset});
        }

        //console.debug('repositioned');
    };

    /**
     * Weboldal psUrl
     *
     * @param p_url
     * @return site
     */
    site.setUrl = function (p_url) {
        psUrl = p_url;

        return site;
    };

    site.getUrl = function () {
        return psUrl;
    };

    site.getToken = function () {
        return token;
    };

    site.setToken = function (p_token) {
        token = p_token;

        return site;
    };

    site.showModal = function (title, msg, event, execute) {
        event.preventDefault();

        var modal = $('#appModal'),
            titleBox = modal.find('.modal-title'),
            contentBox = modal.find('.modal-body');

        if (title == '') {
            titleBox.parents('.modal-header').hide();
        } else {
            titleBox.parents('.modal-header').show();
            titleBox.text(title);
        }

        contentBox.html(msg);

        if ($.isFunction(execute)) {
            $('#modalExecute').on('click', execute);
        }

        modal.modal({backdrop: 'static'});
    };

    /**
     * Oldal megosztás sáv további ikonjainak megjelenítése
     * @param e
     */
    site.openMoreSiteSharingBar = function (e) {
        e.preventDefault();
        var to = null;

        $(e.target).parent().addClass('hidden');
        $('.more-icons').addClass('open');

        $('.site-sharing-tool').find('a').on('mouseenter', function () {
            clearTimeout(to);
        }).on('mouseleave', function () {
            to = setTimeout(function () {
                $('.more-icons').removeClass('open');
                $(e.target).parent().removeClass('hidden');
            }, 800);
        });
    };

    site.showSiteSharingToolbar = function () {
        setTimeout(function () {
            $('.site-sharing-tool').fadeIn(500);
        }, 5000);
    };

    /**
     * Kezdőoldal feladatok
     */
    site.homePage = function () {
        // site sharing bar oldalbetöltés +5mp jelenjen meg
        site.showSiteSharingToolbar();
    };

    site.contentPage = function () {
        var content = $('.scrolled-content'),
            throttleTimeout;

        content.imagesLoaded().always(function () {
            content.jScrollPane({contentWidth: '0px'});
        });

        $(window).on('resize', function () {
            if (!throttleTimeout) {
                throttleTimeout = setTimeout(function () {
                    content.data('jsp').reinitialise();
                    throttleTimeout = null;
                }, 50);
            }
        });

        return site;
    };

    site.top100Page = function (loadedCount, isMobile) {
        var l = loadedCount,
            pending = false,
            scrollBottom = false,
            spinner = $('.spinner'),
            elements = $('.each-other'),
            content = $('.scrolled-content');

        $(document).on('appear', '.load', function () {
            if (!pending) {
                if (scrollBottom && !isMobile) {
                    content.data('jsp').reinitialise();
                } else {
                    pending = true;
                    spinner.show();

                    $.ajax({
                        type: 'POST',
                        url: site.getUrl() + '/top100Scroll',
                        headers: site.getToken(),
                        data: {last: l}
                    }).done(function (result) {
                        pending = false;
                        if (result.success) {

                            spinner.hide();

                            elements.find('.load').removeClass('load');
                            elements.append(result.html);

                            elements.imagesLoaded().done(function () {

                                l = result.last;
                                console.log('last: ' + l);
                                if (l >= 100) {
                                    scrollBottom = true;
                                }

                                // Fake linkek cseréje
                                $('.fake-records').html(result.fakehtml);

                                $('.colorbox').colorbox({
                                    onComplete: site.top100ColorboxComplete,
                                    rel: 'top100',
                                    maxWidth: '95%',
                                    maxHeight: '95%'
                                });

                                if (!isMobile) {
                                    content.data('jsp').reinitialise();
                                }
                            });
                        }
                    });
                }
            }
        });

        $('.load').appear();

        $('.colorbox').colorbox({
            onComplete: site.top100ColorboxComplete,
            rel: 'top100',
            maxWidth: '95%',
            maxHeight: '95%'
        });

        return site.contentPage();
    };

    site.top100ColorboxComplete = function () {
        var img = $('#cboxLoadedContent').find('img'),
            elem = $.colorbox.element();

        if (elem.data().url != '') {
            $(img).wrap('<a href="' + psUrl + '/outn/' + elem.data().second + '" target="_blank"></a>');
        }
    };

    site.to = function (url) {
        window.location.href = psUrl + '/' + url;
    };

    site.cookieLawClose = function () {
        $('.cookielaw').slideUp();
        $.ajax({
            type: 'POST',
            url: psUrl + '/setCookielaw',
            headers: site.getToken()
        });
    };

    return site;


}(Picasecond || {}, jQuery));